.main-footer{
  background-color: var(--color-light-grey);
  font-size: 0.667rem;
  margin-top: 60px;
  padding-bottom: 18px;
  padding-top: 75px;
}

.main-footer__blocks{
  display: grid;
  grid-column-gap: 2%;
  grid-template-columns: repeat(6, 1fr);
}

.main-footer__block{
  p{
    margin-bottom: 15px;
  }
}

.main-footer__block--logos{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.main-footer__block--call{
  border-left: 1px solid var(--color-blue-3);
  padding: 12px 5%;

  h3{
    margin-bottom: 15px;
  }
}

.secondary-navigation__item{
  list-style-type: "-";
  list-style-position: inside;
  text-transform: uppercase;

  &:last-of-type{
    margin-top: 15px;
  }
}

.main-footer__social{
  display: flex;
  gap: 6px;
  margin-top: 60px;
}

.main-footer__credits{
  color: var(--color-blue-3);
  margin-top: 60px;
  text-align: center;
}