@charset "UTF-8";
/* html5doctor.com Reset v1.6.1 - http://cssreset.com */
html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body {
  line-height: 1;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

:root {
  --color-white: #fff;
  --color-light-grey: #E1E1E1;
  --color-black: #000;
  --color-blue-1: #202C40;
  --color-blue-2: #7AB2D3;
  --color-blue-3: #4A628A;
  --color-cyan-1: #B9E5E8;
  --color-cyan-2: #DFF2EB;
  --color-red: #D71921;
  --font-primary: "IT", Arial, Helvetica, sans-serif;
  --font-secondary: "NSM", Courier, monospace;
}

html {
  color: var(--color-blue-1);
  font-size: 112.5%;
}

body {
  font-family: var(--font-primary);
  line-height: 1.2;
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--font-secondary);
  font-weight: 500;
}

a {
  color: inherit;
  text-decoration: none;
}

.box-content {
  padding-left: 5%;
  padding-right: 5%;
}

.is-responsive-image {
  height: auto;
  width: 100%;
}

.is-image-fit {
  display: block;
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.is-composed-title {
  color: var(--color-blue-1);
  font-weight: 200;
  line-height: 1;
  text-transform: uppercase;
}
.is-composed-title strong {
  font-weight: 400;
}

.is-uppercase {
  text-transform: uppercase;
}

.button {
  background: none;
  border: 1px solid var(--color-red);
  border-radius: 12px;
  color: var(--color-red);
  display: inline-block;
  font-size: 0.611rem;
  padding: 6px 9px;
  text-decoration: none;
  text-transform: uppercase;
}

.button--filled {
  background-color: var(--color-white);
}

.action {
  color: var(--color-red);
  font-size: 0.833rem;
}

.action--small {
  font-size: 0.667rem;
}

.action--with-plus:after {
  content: "⊕";
  display: inline-block;
  margin-left: 6px;
}

.action--with-arrow:before {
  content: "→";
  display: inline-block;
  margin-right: 6px;
}

.main-header {
  align-items: center;
  display: flex;
  height: 100px;
  justify-content: space-between;
}

.main-navigation__items {
  display: flex;
  gap: 72px;
}

.main-navigation__link {
  color: var(--color-blue-1);
  font-family: var(--font-secondary);
  text-transform: uppercase;
}

.main-footer {
  background-color: var(--color-light-grey);
  font-size: 0.667rem;
  margin-top: 60px;
  padding-bottom: 18px;
  padding-top: 75px;
}

.main-footer__blocks {
  display: grid;
  grid-column-gap: 2%;
  grid-template-columns: repeat(6, 1fr);
}

.main-footer__block p {
  margin-bottom: 15px;
}

.main-footer__block--logos {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.main-footer__block--call {
  border-left: 1px solid var(--color-blue-3);
  padding: 12px 5%;
}
.main-footer__block--call h3 {
  margin-bottom: 15px;
}

.secondary-navigation__item {
  list-style-type: "-";
  list-style-position: inside;
  text-transform: uppercase;
}
.secondary-navigation__item:last-of-type {
  margin-top: 15px;
}

.main-footer__social {
  display: flex;
  gap: 6px;
  margin-top: 60px;
}

.main-footer__credits {
  color: var(--color-blue-3);
  margin-top: 60px;
  text-align: center;
}