.main-header{
  align-items: center;
  display: flex;
  height: 100px;
  justify-content: space-between;
}

.main-navigation__items{
  display: flex;
  gap: 72px;
}

.main-navigation__link{
  color: var(--color-blue-1);
  font-family: var(--font-secondary);
  text-transform: uppercase;
}