.button{
  background: none;
  border: 1px solid var(--color-red);
  border-radius: 12px;
  color: var(--color-red);
  display: inline-block;
  font-size: 0.611rem;
  padding: 6px 9px;
  text-decoration: none;
  text-transform: uppercase;
}

.button--filled{
  background-color: var(--color-white);
}

.action{
  color: var(--color-red);
  font-size: 0.833rem;
}

.action--small{
  font-size: 0.667rem;
}

.action--with-plus{
  &:after{
    content: '⊕';
    display: inline-block;
    margin-left: 6px;
  }
}

.action--with-arrow{
  &:before{
    content: '→';
    display: inline-block;
    margin-right: 6px;
  }
}