:root{
  --color-white: #fff;
  --color-light-grey: #E1E1E1;
  --color-black: #000;
  --color-blue-1: #202C40;
  --color-blue-2: #7AB2D3;
  --color-blue-3: #4A628A;
  --color-cyan-1: #B9E5E8;
  --color-cyan-2: #DFF2EB;;
  --color-red: #D71921;

  --font-primary: 'IT', Arial, Helvetica, sans-serif;
  --font-secondary: 'NSM', Courier, monospace;
}
