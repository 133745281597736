html{
  color: var(--color-blue-1);
  font-size: 112.5%;
}

body{
  font-family: var(--font-primary);
  line-height: 1.2;
}

h1, h2, h3, h4, h5, h6{
  font-family: var(--font-secondary);
  font-weight: 500;
}

a{
  color: inherit;
  text-decoration: none;
}

.box-content{
  padding-left: 5%;
  padding-right: 5%;
}

.is-responsive-image{
  height: auto;
  width: 100%;
}

.is-image-fit{
  display: block;
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.is-composed-title{
  color: var(--color-blue-1);
  font-weight: 200;
  line-height: 1;
  text-transform: uppercase;

  strong{
    font-weight: 400;
  }
}

.is-uppercase{
  text-transform: uppercase;
}